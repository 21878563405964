/**
 * Print stylesheet
 */
/**
 * Abstracts
 *
 * Global variables, functions, mixins and placeholders.
 */
/**
 * Hides things but keeps it available for screen readers.
 *
 * @soruce https://github.com/inuitcss/inuitcss/blob/develop/tools/_tools.hidden.scss
 * @param $important Apply all styles as !important
 */
/**
 * Undos hidden-visually()
 *
 * @param $important Apply all styles as !important
 */
/**
 * Removes browser-default list styles
 */
/**
 * Vendors
 *
 * CSS files from external libraries and frameworks.
 */
/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block; }

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none; }

/* Elements of HTML (https://www.w3.org/TR/html5/semantics.html)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Add a flattened line height in all browsers (opinionated).
 * 4. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  line-height: 1.5;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 4 */
  -webkit-text-size-adjust: 100%;
  /* 5 */ }

/* Sections (https://www.w3.org/TR/html5/sections.html)
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font sizes and margins on `h1` elements within
 * `section` and `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0; }

/* Grouping content (https://www.w3.org/TR/html5/grouping-content.html)
   ========================================================================== */
/**
 * 1. Correct font sizing inheritance and scaling in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * 1. Correct the height in Firefox.
 * 2. Add visible overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Add a bordered underline effect in all browsers.
 * 2. Remove text decoration in Firefox 40+.
 */
abbr[title] {
  border-bottom: 1px dotted;
  /* 1 */
  text-decoration: none;
  /* 2 */ }

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic; }

/**
 * Add the correct colors in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000; }

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline; }

/**
 * Correct the font size in all browsers.
 */
small {
  font-size: 83.3333%; }

/**
 * Change the positioning on superscript and subscript elements
 * in all browsers (opinionated).
 * 1. Correct the font size in all browsers.
 */
sub,
sup {
  font-size: 83.3333%;
  /* 1 */
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none; }

/* Embedded content (https://www.w3.org/TR/html5/embedded-content-0.html)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Links (https://www.w3.org/TR/html5/links.html#links)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove the gaps in underlines in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline when hovering in all browsers (opinionated).
 */
a:hover {
  outline-width: 0; }

/* Tabular data (https://www.w3.org/TR/html5/tabular-data.html)
   ========================================================================== */
/*
 * Remove border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* transform-style:  (https://www.w3.org/TR/html5/forms.html)
   ========================================================================== */
/**
 * 1. Remove the default styling in all browsers (opinionated).
 * 3. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  background-color: transparent;
  /* 1 */
  border-style: none;
  /* 1 */
  color: inherit;
  /* 1 */
  font-size: 1em;
  /* 1 */
  margin: 0;
  /* 3 */ }

/**
 * Correct the overflow in IE.
 * 1. Correct the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance in Edge, Firefox, and IE.
 * 1. Remove the inheritance in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent the WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Correct the focus styles unset by the previous rule.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
 * Correct the border, margin, and padding in all browsers.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Remove the vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * Correct the cursor style on increment and decrement buttons in Chrome.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari for OS X.
 */
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Correct the text style on placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: .54; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* WAI-ARIA (https://www.w3.org/TR/html5/dom.html#wai-aria)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default; }

/* User interaction (https://www.w3.org/TR/html5/editing.html)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation; }

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

[hidden][aria-hidden="false"]:focus {
  clip: auto; }

/**
 * Base
 *
 * Boilerplate code for the project.
 */
/**
 * 1. Set default type styles for the project.
 * 2. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  color: #0e0e0e;
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  line-height: 1;
  min-height: 100%;
  scroll-behavior: smooth; }

.has-overlay {
  overflow: hidden; }

.overlay-background {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 300ms ease;
  visibility: hidden;
  width: 100%;
  z-index: 99; }
  .overlay-background[aria-hidden='false'] {
    opacity: 1;
    visibility: visible; }

.tippy-content {
  padding: 3rem; }

body {
  background-color: #fff;
  font-size: 1.6rem;
  min-width: 375px;
  overflow-x: hidden;
  overflow-y: scroll; }

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato-Regular.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato-Bold.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/Lato-Italic.woff2") format("woff2"), url("../fonts/Lato-Italic.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/Lato-BoldItalic.woff2") format("woff2"), url("../fonts/Lato-BoldItalic.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Merriweather-Bold.woff2") format("woff2"), url("../fonts/Merriweather-Bold.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: 'MerriweatherLight';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Merriweather-Light.woff2") format("woff2"), url("../fonts/Merriweather-Light.woff") format("woff"); }

.f-sans {
  font-family: "Lato", "Lucida Grande", Tahoma, "Helvetica", "Arial", sans-serif; }

.f-serif {
  font-family: "Merriweather", "Georgia", serif; }

.f-serif-light {
  font-family: "MerriweatherLight", "Georgia", serif; }

.f-w-light {
  font-weight: lighter; }

.f-w-normal {
  font-weight: normal; }

.f-w-bold {
  font-weight: bold; }

.f-s-normal {
  font-style: normal; }

.f-s-italic {
  font-style: italic; }

/**
 * 1. Offset `alt` text from surrounding copy.
 * 2. Keep images from scaling beyond the width of their parents.
 * 3. Prevent images from being stretched when width or height attributes are
 *    present.
 */
img {
  font-style: italic;
  height: auto;
  max-width: 100%; }

/**
 * Theme
 *
 * CSS theme for print
 */
/**
 * Print styles
 */
/**
 * Page margin
 */
@page {
  margin: 15mm 20mm; }

/**
 * Default color
 *
 * Do not change this.
 */
*,
*::before,
*::after {
  background-color: transparent;
  color: #000; }

/**
 * Font
 */
body {
  font-family: Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
  font-size: 12pt;
  line-height: 1.4; }

/**
 * Show hidden elements
 */
[hidden] {
  display: block !important; }

/**
 * Define breaks
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  page-break-after: avoid;
  page-break-inside: avoid; }

a,
blockquote,
code,
figure,
hr,
li,
ol,
pre,
table,
tr,
ul {
  page-break-inside: avoid; }

a,
blockquote,
tr {
  page-break-inside: avoid; }

a,
h2,
h3,
p {
  orphans: 3;
  widows: 3; }

/**
 * Image
 */
img {
  max-width: 100%;
  page-break-after: auto;
  page-break-before: auto;
  page-break-inside: avoid; }

/**
 * Table
 */
thead {
  display: table-header-group; }

th,
td {
  border: 1pt solid #000;
  padding: 5mm; }

/**
 * Form elements
 */
input,
select,
textarea {
  display: block; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/**
 * Code
 */
code {
  font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;
  font-size: 12pt; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

/**
 * Hide elements
 */
/**
 * Display external link url
 */
/**
 * Display abbrevation expansions
 */
/**
 * Display images from @image atom
 */
/**
 * Video/embed placeholder
 */
